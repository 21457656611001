import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Header = (props) => (
    <header id="header" className="alt mainhead">
        <Link to="/" className="logo"><span>Mike Weisert</span><span> UX Designer</span></Link>
        <nav>
        <a href="https://docs.google.com/document/d/1ZhrqCdPchfWBGle1xf4eP5e0IdhJwJ7ucKjC78AdlU4/export?format=doc" target="_blank" rel="noopener noreferrer">Resume <span className="icon fa-download"></span></a>
            <a className="menu-link" onClick={props.onToggleMenu} href="javascript:;">Menu</a>
        </nav>
    </header>
)

Header.propTypes = {
    onToggleMenu: PropTypes.func
}

{/*
    window.addEventListener("scroll", headChk);

    const sitehead = document.querySelector('.mainhead');

    function headChk(sitehead) {

        console.log(sitehead);
        if (window.scrollY > 3 && sitehead != null) {
            sitehead.classList.add("downPage");
            
        } else {
            if (sitehead.classList.contains("downPage")) {
                sitehead.classList.remove("downPage");
            }
        }
    }
*/}

export default Header
