import React from 'react'

const Footer = (props) => (
    <footer id="footer">
        <div className="inner">
            
            <ul className="icons">
                <li><a href="mailto:mweisert@gmail.com">mweisert@gmail.com</a></li>
                <li><a href="https://www.linkedin.com/in/weisertm/" className="icon fa-linkedin"><span className="label">LinkedIn</span></a></li>
            </ul>
            <h6>Portfolio site for Mike Weisert |  UX Designer / Developer based in Northbrook, IL</h6>
        </div>
    </footer>
)

export default Footer
