import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Menu = (props) => (
    <nav id="menu">
        <div className="inner">
                <h3>Experience</h3>
            <ul className="links">
                <li><Link onClick={props.onToggleMenu} to="/fleetmanagement">Redefining Fleet Management</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/ci">Children International</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/professional-services">Relationship-based Business Strategy &amp; Design</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/audio-visual">Enterprise Application Design in Audio Visual</Link></li>
            </ul>
            <a href="https://docs.google.com/document/d/1ZhrqCdPchfWBGle1xf4eP5e0IdhJwJ7ucKjC78AdlU4/edit?usp=sharing" className="button icon fa-download" target="_blank">Resume</a>
            <hr />
            <Link onClick={props.onToggleMenu} to="/">Home</Link>
            {/* <ul className="actions vertical">
                <li><a href="#" className="button special fit">Get Started</a></li>
                <li><a href="#" className="button fit">Log In</a></li>
</ul> */}
        </div>
        <a className="close" onClick={props.onToggleMenu} href="javascript:;">Close</a>
    </nav>
)

Menu.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Menu
